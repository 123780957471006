<template lang="pug">
v-dialog(
  v-model="dialog"
  max-width="800"
)
  template(v-slot:activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      small
      outlined
      color="red"
      class="mx-1 font-weight-bold text-button")
      span(class="font-weight-bold") 刪除
  v-form(
    ref="form"
    v-model="valid"
    @submit.prevent="submit")
    v-card(class="px-5")
      v-card-title
        v-icon mdi-trash-can-outline
        span(class="text-h5") 刪除帳號
      v-card-text
        v-container
          v-row
            v-col 
              p 姓名: {{ item.name }}
              p 帳號: {{ item.mail }}
              v-text-field(
                :rules="rules"
                dense 
                outlined
                label="請輸入刪除後再按確認"
                required
              ) 
      v-card-actions
        v-spacer
        v-btn(
          color="blue darken-1"
          dark
          text
          @click="dialog = false"
        ) 
          span(class="font-weight-bold") 取消
        v-btn(
          color="blue darken-1"
          dark
          type="submit"
        ) 
          span(class="font-weight-bold") 確認
</template>

<script>
export default {
  name: "delDialog",
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    dialog: false,
    valid: true,
    rules: [(v) => !!v || "必填", (v) => v === "刪除" || "輸入內容不符"],
  }),
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.dialog = false;
        this.$emit("del");
      }
    },
  },
  created() {},
};
</script>
