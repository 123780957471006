<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="dataList"
    :items-per-page="-1"
    :loading="loading"
    loading-text="資料載入中..."
    hide-default-footer
  )
    template(v-slot:item.isEnable="{ item }")
      v-switch(
        v-model="item.isEnable"
        inset
        hide-details
        :disabled="!right.operate.allowEditRow"
        @change="handleSwitchEnable(item)"
      )
    template(v-slot:item.actions="{ item }" v-if="right.operate.allowEditRow")
      v-btn(
        v-if="item.id && item === editingItem"
        @click="cancelEditItem(item)"
        small
        dark
        color="yellow darken-4"
        class="mx-1 text-button") 取消
      EditDialogComponent(
        v-if="item.id && item !== editingItem"
        :item="item"
        :pageList="pageList"
        :churchList="churchList"
        :operateList="operateList"
        @update="$emit('update', item)"
      )
      DelDialogComponent(
        v-if="item.id && item !== editingItem && right.operate.allowDeleteRow"
        :item="item"
        @del="$emit('del', item)"
      )
</template>
<script>
import EditDialogComponent from "./editDialog.vue";
import DelDialogComponent from "./delDialog.vue";
export default {
  name: "dedicationNumberSearchTable",
  components: {
    EditDialogComponent,
    DelDialogComponent,
  },
  props: {
    loading: {
      type: Boolean,
    },
    dataList: {
      type: Array,
    },
    pageList: {
      type: Array,
    },
    churchList: {
      type: Array,
    },
    operateList: {
      type: Array,
    },
  },
  data() {
    return {
      headers: [
        { text: "帳號", align: "center", sortable: false, value: "mail" },
        { text: "姓名", align: "center", sortable: false, value: "name" },
        {
          text: "最後修改時間",
          align: "center",
          sortable: false,
          value: "updateTime",
        },
        {
          text: "編輯者",
          align: "center",
          sortable: false,
          value: "updateUser",
        },
        {
          text: "是否啟用",
          align: "center",
          value: "isEnable",
          sortable: false,
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      editingItem: {},
      deleteItem: {},
    };
  },
  computed: {
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    editItem(item) {
      this.editingItem = item;
    },
    cancelEditItem() {
      this.editingItem = {};
    },
    updateItem() {
      this.$emit("update", this.editingItem);
      this.editingItem = {};
    },
    addAccount() {
      this.$emit("add");
    },
    handleSwitchEnable(item) {
      this.$emit("enable", item);
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
}

::v-deep
  .v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--switch.v-input--switch--inset {
  margin: 0;
}

::v-deep .v-input--switch--inset .v-input--selection-controls__input {
  margin: 0 auto;
}

.edit-component {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 80px;
}
.edit-date-input {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 180px;
}
.checkbox {
  width: fit-content;
  margin: 0 auto;
}
</style>
