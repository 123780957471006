<template lang="pug">
v-container(fluid)
  div(class="d-flex justify-space-between mb-2")
    h2(class="blue-grey--text")
      v-icon(class="mr-1") mdi-shield-account-outline
      span 帳號權限管理
  div(class="d-flex")
    AddDialog(
      v-if="right.operate.allowAddRow"
      class="ml-auto" 
      :pageList="pageList"
      :churchList="churchList"
      :operateList="operateList"
      @add="handleAddAccount" )
  SearchTable(
    :loading="loading"
    :dataList="listData"
    :pageList="pageList"
    :churchList="churchList"
    :operateList="operateList"
    @del="handleDelAccount"
    @update="handleUpdateAccount"
    @enable="handleSwitchEnable"
  )
</template>

<script>
import AddDialog from "@/components/account/addDialog.vue";
import SearchTable from "@/components/account/searchTable";
export default {
  name: "accountSearch",
  components: {
    SearchTable,
    AddDialog,
  },
  data: () => ({
    listData: [],
    loading: false,
    pageList: [],
    churchList: [],
    operateList: [],
  }),
  computed: {
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    async getClcGroupSelect() {
      try {
        let groupRes = await this.$api.common.commonAPI.getClcGroupList();
        if (groupRes && groupRes.data.code === 200) {
          this.$store.dispatch("group/setGroup", groupRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPageList() {
      try {
        let pageListRes = await this.$api.account.accountAPI.getAdminPageList();
        if (pageListRes && pageListRes.data.code === 200) {
          this.pageList = pageListRes.data.content;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getChurchList() {
      try {
        let churchListRes =
          await this.$api.account.accountAPI.getAdminChurchList();
        if (churchListRes && churchListRes.data.code === 200) {
          this.churchList = churchListRes.data.content;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getOperateList() {
      try {
        let operateListRes =
          await this.$api.account.accountAPI.getAdminOperateList();
        if (operateListRes && operateListRes.data.code === 200) {
          this.operateList = operateListRes.data.content;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getRightList() {
      try {
        let pageRes = await this.$api.auth.getPageList();
        if (pageRes.data.code === 200) {
          this.items = pageRes.data.content;
          this.$store.dispatch("auth/setRight", {
            right: pageRes.data.content
          });
        }
      } catch (err) {
        console.log(err.code);
      }
    },
    async getList(payload) {
      try {
        this.loading = true;
        let listRes = await this.$api.account.accountAPI.getList(payload);
        if (listRes && listRes.data.code === 200) {
          this.listData = listRes.data.content;
        }
        if (!listRes.data.success && listRes.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: listRes.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
          if (listRes.data.isRedirect) {
            this.$router.push("/permissions");
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async handleAddAccount(item) {
      try {
        await this.$api.account.accountAPI.addAccount(item);
        this.$store.dispatch("notification/openNotification", {
          msg: "已新增",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList({});
        this.getRightList();
      } catch (err) {
        console.log(err);
      }
    },
    async handleUpdateAccount(item) {
      try {
        delete item["id"];
        await this.$api.account.accountAPI.modifyAccount(item);
        this.$store.dispatch("notification/openNotification", {
          msg: "已更新",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getRightList();
        location.reload();
      } catch (err) {
        console.log(err);
      }
    },
    async handleDelAccount(item) {
      try {
        await this.$api.account.accountAPI.deleteAccount({ dataId: item.id });
        this.$store.dispatch("notification/openNotification", {
          msg: "已刪除",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList({});
      } catch (err) {
        console.log(err);
      }
    },
    async handleSwitchEnable(item) {
      try {
        await this.$api.account.accountAPI.modifyEnable({
          infoId: item.dataId || item.id,
          isEnable: item.isEnable ? 1 : 0,
        });
        this.$store.dispatch("notification/openNotification", {
          msg: "已更新",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList({});
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    this.getClcGroupSelect();
    this.getList({});
    this.getPageList();
    this.getChurchList();
    this.getOperateList();
  },
};
</script>
