<template lang="pug">
v-dialog(
  v-model="editDialog"
  max-width="800"
)
  template(v-slot:activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      small
      outlined
      color="teal"
      class="mx-1 font-weight-bold text-button")
      span(class="font-weight-bold") 編輯
  v-form(
    ref="form"
    v-model="valid"
    @submit.prevent="handleUpdateAccount")
    v-card
      v-card-title
        v-icon mdi-pencil
        span(class="text-h5") 編輯帳號
      v-card-text
        v-container
          editGroup(:value="editItem.groupId" @groupId="handleSearchGroup")
          v-row
            v-col
              v-select(
                v-model="editItem.memberId"
                :items="groupNameOptions"
                :rules="rules"
                item-text="name"
                item-value="memberId"
                label="姓名"
                required
                hide-details
                @change="handleSelectMember"
              )   
            v-col
              p(v-if="editItem.phone" class="mt-5") 手機 {{ editItem.phone }}
          v-row
            v-col
              v-text-field(
                v-model="editItem.mail"
                :rules="rules"
                label="帳號(電子信箱)"
                required
              )
            v-col(v-if="showReset")
              v-text-field(
                v-model="newPwd"
                label="重設密碼"
              ) 
            v-col
              v-btn(
                color="blue darken-1 mt-3"
                dark
                @click="handleNewPassword"
              ) 
                span(class="font-weight-bold") 重設密碼
          v-row
            v-col
              v-select(
                v-model="editItem.pageIds"
                :items="pageList"
                chips
                multiple 
                label="選單權限"
                hide-details
              )
          v-row
            v-col
              v-select(
                v-model="editItem.churchs"
                :items="churchList"
                chips
                multiple 
                label="範圍權限"
                hide-details
              )
          v-row
            v-col
              v-select(
                v-model="editItem.operateIds"
                :items="operateList"
                chips
                multiple 
                label="範圍權限"
                hide-details
              )
      v-card-actions
        v-spacer
        v-btn(
          color="blue darken-1"
          dark
          text
          @click="editDialog = false"
        ) 
          span(class="font-weight-bold") 取消
        v-btn(
          color="blue darken-1 px-5 ma-5"
          dark
          type="submit"
        ) 
          span(class="font-weight-bold") 確認

</template>

<script>
import editGroup from "./editGroup.vue";
export default {
  name: "editDialog",
  props: {
    item: {
      type: Object,
    },
    pageList: {
      type: Array,
    },
    churchList: {
      type: Array,
    },
    operateList: {
      type: Array,
    },
  },
  components: {
    editGroup,
  },
  data: () => ({
    editDialog: false,
    valid: true,
    rules: [(v) => !!v || "必填"],
    groupNameOptions: [],
    newPwd: null,
    showReset: false,
    editItem: {
      mail: null,
      name: null,
      memberId: null,
      phone: null,
      password: null,
      pageIds: [],
      operateIds: [],
      churchs: [],
    },
  }),
  methods: {
    async handleSearchGroup(val) {
      if (val && this.editDialog) {
        try {
          let memberListRes = await this.$api.account.accountAPI.getMemberName({
            groupId: val,
          });
          if (memberListRes && memberListRes.data.code === 200) {
            this.groupNameOptions = memberListRes.data.content;
            const editItemObj = this.groupNameOptions.find((item) => {
              return item.name === this.item.name;
            });
            this.editItem.memberId = editItemObj.memberId;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    handleSelectMember(val) {
      const selectedMember = this.groupNameOptions.find((item) => {
        return item.memberId === val;
      });
      this.editItem.name = selectedMember.name;
      this.editItem.phone = selectedMember.phone;
      this.editItem.mail = selectedMember.mail;
    },
    async handleNewPassword() {
      this.showReset = true
      try {
        let newPwdRes = await this.$api.account.accountAPI.getResetPassword();
        if (newPwdRes && newPwdRes.data.code === 200) {
          this.newPwd = newPwdRes.data.content;
        }
      } catch (err) {
        console.log(err);
      }
    },
    handleUpdateAccount() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.editDialog = false;
        this.editItem.churchs = this.editItem.churchs.map((item) => {
          return `${item}`;
        });
        this.editItem.password =
          this.newPwd && this.newPwd.trim() !== "" ? this.newPwd : "";
        this.$emit("update", this.editItem);
        this.editItem = {
          mail: null,
          name: null,
          memberId: null,
          phone: null,
          password: null,
          pageIds: [],
          operateIds: [],
          churchs: [],
        };
      }
    },
  },
  created() {
    this.editItem = this.item;
    this.editItem.dataId = this.item.id;
    this.editItem.churchs = this.editItem.churchs.map((item) => {
      return Number(item);
    });
    this.handleSearchGroup(this.editItem.groupId);
  },
};
</script>
