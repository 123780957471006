<template lang="pug">
v-row(no-gutter)
  v-col(cols="12" md="4" class="px-1")
    v-select(
      v-model="groupType" 
      :items="groupTypeOptions"
      label="組別"
      hide-details
    )
  v-col(cols="12" md="4" class="px-1")
    v-select(
      v-model="districtName" 
      :items="districtOptions"
      :disabled="groupType === ''"
      label="牧區"
      hide-details
    )
  v-col(cols="12" md="4" class="px-1")
    v-select(
      v-model="groupId" 
      :items="groupOptions"
      item-text="groupName"
      item-value="groupId"
      :disabled="districtName === ''"
      label="小組"
      hide-details
    )
</template>

<script>
export default {
  name: "Group",
  props: {
    value: {
      type: String,
    },
  },
  data: () => ({
    groupType: "",
    districtName: "",
    groupId: "",
  }),
  watch: {
    groupId(val) {
      this.$emit("groupId", val);
    },
  },
  computed: {
    groupTypeOptions() {
      return this.$store.state.group.groupType;
    },
    groups() {
      return this.$store.state.group.group;
    },
    districtOptions() {
      const groupMap = this.groups
        .filter((group) => group.group_type_name === this.groupType)
        .reduce((acc, g) => {
          acc[g.district_name] = true;
          return acc;
        }, {});
      return Object.keys(groupMap).map((g) => {
        return g;
      });
    },
    groupOptions() {
      return this.groups
        .filter((g) => g.district_name === this.districtName)
        .map((g) => ({ groupName: g.group_name, groupId: g.group_id }));
    },
  },
  created() {
    this.groupId = this.value;
    let selectedGroup = this.groups.find((item) => {
      return item.group_id === this.value;
    });
    this.groupType = selectedGroup ? selectedGroup.group_type_name : "";
    this.districtName = selectedGroup ? selectedGroup.district_name : "";
  },
};
</script>
<style scoped>
.edit-group {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  width: 100%;
}
</style>
