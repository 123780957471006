<template lang="pug">
v-dialog(
  v-model="addDialog"
  max-width="800"
)
  template(v-slot:activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      class="ma-2 ml-auto font-weight-bold text-button" dark color="success")
      v-icon(dark) mdi-plus
      span(class="font-weight-bold") 新增
  v-stepper(v-model="addStepper")
    v-stepper-header
      v-stepper-step(:complete="addStepper > 1" step="1") 新增帳號：STEP 1
      v-divider
      v-stepper-step(step="2") 新增帳號：STEP 2
    v-stepper-items
      v-stepper-content(step="1")
        v-form(
          ref="form1"
          v-model="valid")
          v-card
            v-card-text
              v-container
                Group(@groupId="handleSearchGroup")
                v-row
                  v-col
                    v-select(
                      v-model="addData.memberId"
                      :items="groupNameOptions"
                      :rules="rules"
                      item-text="name"
                      item-value="memberId"
                      label="姓名"
                      required
                      hide-details
                      @change="handleSelectMember"
                    )   
                  v-col
                    p(v-if="addData.phone" class="mt-5") 手機 {{ addData.phone }}
            v-card-actions
              v-spacer
              v-btn(
                color="blue darken-1"
                dark
                text
                @click="addDialog = false"
              ) 
                span(class="font-weight-bold") 取消
              v-btn(
                v-if="addData.memberId"
                color="green darken-1"
                dark
                @click="addStepper = 2"
              ) 
                span(class="font-weight-bold") 下一步
                v-icon(dark) mdi-chevron-right 
      v-stepper-content(step="2")
        v-form(
          ref="form2"
          v-model="valid"
          @submit.prevent="handleAddAccount")
          v-card
            v-card-text
              v-container
                v-row
                  v-col
                    v-text-field(
                      v-model="addData.mail"
                      :rules="rules"
                      label="帳號(電子信箱)"
                      required
                    )  
                  v-col
                    v-text-field(
                      v-model="addData.password"
                      :rules="rules"
                      label="密碼"
                      type="password"
                      required
                    )  
                v-row
                  v-col
                    v-select(
                      v-model="addData.pageIds"
                      :items="pageList"
                      chips
                      multiple 
                      label="選單權限"
                      hide-details
                    )
                v-row
                  v-col
                    v-select(
                      v-model="addData.churchs"
                      :items="churchList"
                      chips
                      multiple 
                      label="範圍權限"
                      hide-details
                    )
                v-row
                  v-col
                    v-select(
                      v-model="addData.operateIds"
                      :items="operateList"
                      chips
                      multiple 
                      label="範圍權限"
                      hide-details
                    )
            v-card-actions
              v-spacer
              v-btn(
                color="blue darken-1"
                dark
                text
                @click="addDialog = false"
              ) 
                span(class="font-weight-bold") 取消
              v-btn(
                color="green darken-1"
                dark
                @click="addStepper = 1"
              ) 
                v-icon(dark) mdi-chevron-left 
                span(class="font-weight-bold") 上一步
              v-btn(
                color="blue darken-1 px-5 ml-5"
                dark
                type="submit"
              ) 
                v-icon(dark) mdi-plus
                span(class="font-weight-bold") 新增
</template>

<script>
import Group from "../common/group.vue";
export default {
  name: "addDialog",
  props: {
    pageList: {
      type: Array,
    },
    churchList: {
      type: Array,
    },
    operateList: {
      type: Array,
    },
  },
  components: {
    Group,
  },
  data: () => ({
    addDialog: false,
    addStepper: 1,
    valid: true,
    rules: [(v) => !!v || "必填"],
    groupNameOptions: [],
    addData: {
      mail: null,
      name: null,
      memberId: null,
      phone: null,
      password: null,
      pageIds: [],
      operateIds: [],
      churchs: [],
    },
  }),
  watch: {
    addDialog(val) {
      if (!val) {
        this.addData = {
          mail: null,
          name: null,
          memberId: null,
          password: null,
          pageIds: this.pageList.map((item) => {
            return item.value;
          }),
          operateIds: this.operateList.map((item) => {
            return item.value;
          }),
          churchs: this.churchList.map((item) => {
            return item.value;
          }),
        };
        this.addStepper = 1;
        this.$refs.form1.reset();
        this.$refs.form2.reset();
      }
    },
    pageList(val) {
      this.addData.pageIds = val.map((item) => {
        return item.value;
      });
    },
    operateList(val) {
      this.addData.operateIds = val.map((item) => {
        return item.value;
      });
    },
    churchList(val) {
      this.addData.churchs = val.map((item) => {
        return item.value;
      });
    },
  },
  methods: {
    async handleSearchGroup(val) {
      if (val && this.addDialog) {
        try {
          let memberListRes = await this.$api.account.accountAPI.getMemberName({
            groupId: val,
          });
          if (memberListRes && memberListRes.data.code === 200) {
            this.groupNameOptions = memberListRes.data.content;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    handleSelectMember(val) {
      const selectedMember = this.groupNameOptions.find((item) => {
        return item.memberId === val;
      });
      this.addData.name = selectedMember.name;
      this.addData.phone = selectedMember.phone;
      this.addData.mail = selectedMember.mail;
    },
    handleAddAccount() {
      const valid = this.$refs.form1.validate() && this.$refs.form2.validate();
      if (valid) {
        this.addDialog = false;
        this.addData.churchs = this.addData.churchs.map((item) => {
          return `${item}`;
        });
        this.$emit("add", this.addData);
        this.addData = {
          mail: null,
          name: null,
          memberId: null,
          password: null,
          pageIds: this.pageList.map((item) => {
            return item.value;
          }),
          operateIds: this.operateList.map((item) => {
            return item.value;
          }),
          churchs: this.churchList.map((item) => {
            return item.value;
          }),
        };
      }
    },
  },
};
</script>
